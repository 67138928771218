<template>
  <div class="RetrievePassword">
    <!--  找回密码-->
    <a @click="returnAccoutPassW"><a-icon type="left" /> 返回登录</a>
    <h2
      style="
        font-weight: bold;
        color: #333333;
        letter-spacing: 3px;
        text-align: center;
        margin-top: 30px;
      "
    >
      找回密码-验证手机号
    </h2>
    <div style="margin-top: 40px">
      <div>
        <!--      <a-form-model-->
        <!--          ref="ruleForm2"-->
        <!--          :model="form"-->
        <!--          :rules="rules2"-->
        <!--          :label-col="labelCol"-->
        <!--          :wrapper-col="wrapperCol"-->
        <!--      >-->
        <!--        <a-form-model-item prop="phoneNumber">-->
        <!--          <a-input size="large" class="input" v-model="form.phoneNumber" placeholder="请输入手机号"/>-->
        <!--        </a-form-model-item>-->
        <!--        <a-form-model-item prop="password">-->
        <!--         <a-input-->
        <!--              class="input"-->
        <!--              size="large"-->
        <!--              v-model="form.password"-->
        <!--              placeholder="请输入验证码"-->
        <!--              @keydown.enter="onSubmit"-->
        <!--          >-->
        <!--              <span slot="suffix">-->
        <!--                <a-->
        <!--                    @click="phoneCodeLogin"-->
        <!--                    :disabled="phoneCodeLoginDisabled"-->
        <!--                >{{ phoneCodeLoginText }}</a-->
        <!--                >-->
        <!--              </span>-->
        <!--          </a-input>-->
        <!--        </a-form-model-item>-->
        <!--        <a-form-model-item>-->
        <!--          <a-button size="large" type="primary" @click="onSubmit" class="input btnSty" style="margin-top: 30px;height: 40px;">-->
        <!--            立即提交-->
        <!--          </a-button>-->
        <!--        </a-form-model-item>-->
        <!--      </a-form-model>-->
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item :validate-status="fvalidateStatus" :help="helpMessage">
          <a-input
            size="large"
            class="input"
            v-decorator="[
              'phoneNumber',
              {
                rules: [
                  { required: true, message: '请输入手机号' },
                  { validator: validateMobile },
                ],
              },
            ]"
            type="tel"
            placeholder="请输入手机号"
          />
        </a-form-item>
        <a-form-item :validate-status="fvalidatePass" :help="helpPass">
          <a-input
            size="large"
            class="input"
            v-decorator="[
              'password',
              {
                rules: [
                  { required: true, message: '请输入验证码' },
                  { validator: validatePass },
                ],
              },
            ]"
            type="tel"
            placeholder="请输入验证码"
          >
            <span slot="suffix">
              <a @click="phoneCodeLogin" :disabled="phoneCodeLoginDisabled">{{
                phoneCodeLoginText
              }}</a>
            </span>
          </a-input>
        </a-form-item>

        <!-- 其他表单项 -->

        <a-form-item>
          <a-button
            size="large"
            type="primary"
            class="input btnSty"
            style="margin-top: 30px; height: 40px"
            html-type="submit"
            >登录</a-button
          >
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import {
  adminForgetPassWordSMS,
  postLoginPhoneCode,
  setUserId,
} from "@/service/common";
import { jsonp } from "@/utils/jsonp";
import { setXToken } from "@/service/base";
import { keyApi } from "@/utils/keyVal";
export default {
  name: "RetrievePassword",
  data() {
    return {
      keys: keyApi(),
      // rules2: {
      //   phoneNumber: [{ required: true, message: '请输入手机号', trigger: 'blur' },
      //     { min: 11, max: 11, message: '请规范输入手机号', trigger: 'blur' },],
      //   password:[{required: true, message: '请输入密码', trigger: 'blur' }],
      // },
      // form: {
      //   phoneNumber:'',
      //   password: '',
      // },
      form: this.$form.createForm(this),
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      phoneCodeLoginDisabled: false,
      phoneCodeLoginText: "获取验证码",

      fvalidateStatus: "", //error
      helpMessage: "", //请填写手机号码
      fvalidatePass: "", //error
      helpPass: "", //请填写手机号码
    };
  },

  methods: {
    callback() {},
    onSubmit() {
      this.$refs.ruleForm2.validate(async (valid) => {
        if (valid) {
          let form = this.form;
          let data = {
            tel: form.phoneNumber, //手机号
            password: form.password, //验证码
          };
          const response = await adminForgetPassWordSMS(data);
          if (response.code === 200) {
            await setXToken(undefined, undefined, undefined, response.data);
            this.$store.dispatch("loginShow", 3);
          } else {
            this.$message.warning("验证码不正确！");
          }
        } else {
          return false;
        }
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let data = {
            tel: values.phoneNumber, //手机号
            password: values.password, //验证码
          };
          const response = await adminForgetPassWordSMS(data);
          if (response.code === 200) {
            await setXToken(undefined, undefined, undefined, response.data);
            this.$store.dispatch("loginShow", 3);
          } else {
            this.$message.warning("验证码不正确！");
          }
        }
      });
    },
    validateMobile(rule, value, callback) {
      if (!value) {
        this.fvalidateStatus = "error";
        this.helpMessage = "请输入手机号";
      } else if (!/^1[0-9]{10}$/.test(value)) {
        this.fvalidateStatus = "error";
        this.helpMessage = "手机号码格式不正确";
      } else {
        callback();
        this.fvalidateStatus = "";
        this.helpMessage = "";
      }
    },
    validatePass(rule, value, callback) {
      if (!value) {
        this.fvalidatePass = "error";
        this.helpPass = "请输入验证码";
      } else {
        callback();
        this.fvalidatePass = "";
        this.helpPass = "";
      }
    },
    async phoneCodeLogin() {
      //验证码登录-获取验证码
      const form = this.form;
      let phoneNumber = form.getFieldValue("phoneNumber");
      if (phoneNumber) {
        let data = {
          tel: phoneNumber,
        };
        const response = await postLoginPhoneCode(data);
        if (response.code === 0) {
          this.$message.success("发送成功");
          this.phoneCodeLogin60s(); // 调用验证码倒计时
        } else {
          this.$message.warning(response.message);
        }
      } else {
        // this.$message.warning("请填写手机号码");
        this.fvalidateStatus = "error";
        this.helpMessage = "请填写手机号码";
      }
    },
    phoneCodeLogin60s() {
      // 验证码登录-获取验证码-60s倒计时
      this.phoneCodeLoginDisabled = true;
      let count = 60;
      let interval = setInterval(() => {
        count--;
        this.phoneCodeLoginText = count + "s";

        if (count === 0) {
          clearInterval(interval);
          this.phoneCodeLoginText = "重新获取";
          this.phoneCodeLoginDisabled = false;
        }
      }, 1000);
    },
    returnAccoutPassW() {
      this.$store.dispatch("loginShow", 1);
    },
  },
};
</script>

<style scoped lang="scss">
.RetrievePassword {
  width: 100%;
  margin-top: 20px;
  .input {
    width: 382px;
  }
  .btnSty {
    background: linear-gradient(to right, #6fb1ff, #095fff);
    border: none;
  }
  .ant-input {
    .ant-input-affix-wrapper .ant-input:not(:first-child) {
      padding-left: 40px;
    }
  }
}
</style>
