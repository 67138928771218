var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Register"},[_c('h2',{staticStyle:{"font-weight":"bold","color":"#333333","letter-spacing":"3px","text-align":"center","margin-top":"50px"}},[_vm._v(" 注册成功，即可免费试用 ")]),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.onSubmit}},[_c('a-form-item',{attrs:{"validate-status":_vm.fvalidateName,"help":_vm.helpName}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                { required: true, message: '请输入您的姓名' },
                { validator: _vm.validateName } ],
            } ]),expression:"[\n            'name',\n            {\n              rules: [\n                { required: true, message: '请输入您的姓名' },\n                { validator: validateName },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"请输入您的姓名"}})],1),_c('a-form-item',{attrs:{"validate-status":_vm.fvalidateStatus,"help":_vm.helpMessage}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'phoneNumber',
            {
              rules: [
                { required: true, message: '请填写手机号码' },
                { validator: _vm.validateMobile } ],
            } ]),expression:"[\n            'phoneNumber',\n            {\n              rules: [\n                { required: true, message: '请填写手机号码' },\n                { validator: validateMobile },\n              ],\n            },\n          ]"}],attrs:{"type":"tel","placeholder":"请输入手机号"}})],1),_c('a-form-item',{attrs:{"validate-status":_vm.fvalidatePass,"help":_vm.helpPass}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                { required: true, message: '请填写验证码' },
                { validator: _vm.validatePass } ],
            } ]),expression:"[\n            'password',\n            {\n              rules: [\n                { required: true, message: '请填写验证码' },\n                { validator: validatePass },\n              ],\n            },\n          ]"}],attrs:{"type":"tel","placeholder":"请输入验证码"}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_c('a',{attrs:{"disabled":_vm.phoneCodeLoginDisabled},on:{"click":_vm.phoneCodeLogin}},[_vm._v(_vm._s(_vm.phoneCodeLoginText))])])])],1),_c('a-form-item',{attrs:{"validate-status":_vm.fvalidateType,"help":_vm.helpType}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'type',
            {
              rules: [
                { required: true, message: '请阅读并同意用户服务协议' },
                { validator: _vm.validateType } ],
            } ]),expression:"[\n            'type',\n            {\n              rules: [\n                { required: true, message: '请阅读并同意用户服务协议' },\n                { validator: validateType },\n              ],\n            },\n          ]"}],staticStyle:{"text-align":"left","width":"382px"}},[_c('a-checkbox',{attrs:{"value":"1","name":"type"}},[_c('span',{staticStyle:{"color":"#999999","font-size":"12px"}},[_vm._v("登录前请仔细阅读")])]),_c('a',{staticStyle:{"color":"#3989f5"},on:{"click":_vm.openLink}},[_vm._v("《用户服务协议》")])],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"input btnSty",staticStyle:{"height":"40px"},attrs:{"type":"primary","html-type":"submit"}},[_vm._v("登录")])],1),_c('div',{staticStyle:{"margin-top":"-15px","width":"382px","display":"flex","justify-content":"center"}},[_c('a',{staticStyle:{"font-size":"12px"},on:{"click":_vm.returnAccoutPassW}},[_vm._v("已有账号，快速登录>>")])])],1)],1),_c('a-modal',{attrs:{"width":"400px"},on:{"cancel":_vm.cancel},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('p',[_vm._v("恭喜您成功注册医驰后台管理系统！")]),_c('div',{style:({
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      })},[_c('a-button',{on:{"click":function($event){return _vm.handleOk('cancel')}}},[_vm._v(" 客服介绍 ")]),_c('a-button',{staticStyle:{"margin-left":"190px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleOk('ok')}}},[_vm._v(" 直接进入 ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }