<template>
  <div class="AccountPassword">
    <!--  账号密码/验证码登录-->
    <h2
      style="
        font-weight: bold;
        color: #333333;
        letter-spacing: 3px;
        text-align: center;
        margin-top: 50px;
      "
    >
      欢迎登录医驰会务管理系统
    </h2>
    <div style="margin-top: 20px">
      <a-tabs
        default-active-key="1"
        @change="callback"
        :tabBarGutter="80"
        :tabBarStyle="{
          borderBottom: 'unset',
          textAlign: 'center',
        }"
      >
        <a-tab-pane key="1" tab="密码登录">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item prop="phoneNumber">
              <a-input
                size="large"
                class="input"
                v-model="form.phoneNumber"
                placeholder="请输入手机号"
              />
            </a-form-model-item>
            <a-form-model-item prop="password">
              <a-input-password
                size="large"
                class="input"
                @keydown.enter="onSubmit('MMDL')"
                v-model="form.password"
                placeholder="请输入密码"
              />
            </a-form-model-item>
            <a-form-model-item prop="type">
              <a-checkbox-group
                v-model="form.type"
                style="text-align: left; width: 382px"
              >
                <a-checkbox value="1" name="type">
                  <span style="color: #999999; font-size: 12px"
                    >登录前请仔细阅读</span
                  ></a-checkbox
                ><a style="color: #3989f5" @click="openLink"
                  >《用户服务协议》</a
                >
                <!--                及<a style="color: #3989f5">《隐私政策》</a>-->
              </a-checkbox-group>
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                type="primary"
                @click="onSubmit('MMDL')"
                class="input btnSty"
                style="height: 40px; font-size: 14px"
              >
                马上登录
              </a-button>
            </a-form-model-item>
            <div class="btnFont">
              <span class="leftFont" @click="forgetPassword">忘记密码</span>
              <a style="font-size: 12px; color: #3989f5" @click="registerBtn"
                >没有账号，立即注册</a
              >
            </div>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="2" tab="验证码登录" force-render>
          <div>
            <!--        <a-form-model-->
            <!--            ref="ruleForm2"-->
            <!--            :model="form2"-->
            <!--            :rules="rules2"-->
            <!--            :label-col="labelCol"-->
            <!--            :wrapper-col="wrapperCol"-->
            <!--        >-->
            <!--          <a-form-model-item prop="phoneNumber">-->
            <!--            <a-input size="large" class="input" v-model="form2.phoneNumber" placeholder="请输入手机号"/>-->
            <!--          </a-form-model-item>-->
            <!--          <a-form-model-item prop="password">-->
            <!--&lt;!&ndash;            <a-input class="input" v-model="form2.password" placeholder="请输入验证码"/>&ndash;&gt;-->
            <!--            <a-input-->
            <!--                class="input"-->
            <!--                size="large"-->
            <!--                v-model="form2.password"-->
            <!--                placeholder="请输入验证码"-->
            <!--                @keydown.enter="onSubmit('YZMDL')"-->
            <!--            >-->
            <!--              <span slot="suffix">-->
            <!--                <a-->
            <!--                    @click="phoneCodeLogin"-->
            <!--                    :disabled="phoneCodeLoginDisabled"-->
            <!--                >{{ phoneCodeLoginText }}</a-->
            <!--                >-->
            <!--              </span>-->
            <!--            </a-input>-->
            <!--          </a-form-model-item>-->
            <!--          <a-form-model-item prop="type">-->
            <!--            <a-checkbox-group v-model="form2.type" style="text-align: left;width: 382px">-->
            <!--              <a-checkbox value="1" name="type">-->
            <!--                <span style="color: #999999;font-size: 12px">登录前请仔细阅读</span>-->
            <!--              </a-checkbox>-->
            <!--              <a style="color: #3989F5;">《服务协议》</a>及<a style="color: #3989F5;">《隐私政策》</a>-->
            <!--            </a-checkbox-group>-->
            <!--          </a-form-model-item>-->
            <!--          <a-form-model-item>-->
            <!--            <a-button size="large" type="primary" @click="onSubmit('YZMDL')" class="input btnSty" style="height: 40px">-->
            <!--              马上登录-->
            <!--            </a-button>-->
            <!--          </a-form-model-item>-->
            <!--        </a-form-model>-->
          </div>
          <a-form :form="form2" @submit="handleSubmit">
            <a-form-item :validate-status="fvalidateStatus" :help="helpMessage">
              <a-input
                class="input"
                size="large"
                v-decorator="[
                  'phoneNumber',
                  {
                    rules: [
                      { required: true, message: '请输入手机号' },
                      { validator: validateMobile },
                    ],
                  },
                ]"
                type="tel"
                placeholder="请输入手机号"
              />
            </a-form-item>
            <a-form-item :validate-status="fvalidatePass" :help="helpPass">
              <a-input
                class="input"
                size="large"
                v-decorator="[
                  'password',
                  {
                    rules: [
                      { required: true, message: '请输入验证码' },
                      { validator: validatePass },
                    ],
                  },
                ]"
                type="tel"
                placeholder="请输入验证码"
              >
                <span slot="suffix">
                  <a
                    @click="phoneCodeLogin"
                    :disabled="phoneCodeLoginDisabled"
                    >{{ phoneCodeLoginText }}</a
                  >
                </span>
              </a-input>
            </a-form-item>
            <a-form-item :validate-status="fvalidateType" :help="helpType">
              <a-checkbox-group
                v-decorator="[
                  'type',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请阅读并同意用户服务协议',
                      },
                      { validator: validateType },
                    ],
                  },
                ]"
                style="text-align: left; width: 382px"
              >
                <a-checkbox value="1" name="type">
                  <span style="color: #999999; font-size: 12px"
                    >登录前请仔细阅读</span
                  ></a-checkbox
                ><a style="color: #3989f5" @click="openLink"
                  >《用户服务协议》</a
                >
                <!--                及<a style="color: #3989f5">《隐私政策》</a>-->
              </a-checkbox-group>
            </a-form-item>
            <a-form-item>
              <a-button
                size="large"
                type="primary"
                html-type="submit"
                class="input btnSty"
                style="height: 40px; font-size: 14px"
                >马上登录</a-button
              >
            </a-form-item>
            <div class="btnFont">
              <span class="leftFont" @click="forgetPassword">忘记密码</span>
              <a style="font-size: 12px; color: #3989f5" @click="registerBtn"
                >没有账号，立即注册</a
              >
            </div>
          </a-form>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import {
  login,
  postLoginPhoneCode,
  postLoginPhoneCodeSubmit,
  setUserId,
} from "@/service/common";
import { jsonp } from "@/utils/jsonp";
import { setXToken } from "@/service/base";
import { keyApi } from "@/utils/keyVal";
import { operatingSystem } from "@/utils/getOperatingSystem";
import headerConfig from "@/service/api_header_config";
export default {
  name: "AccountPassword",
  data() {
    return {
      headerConfig: headerConfig,
      operatingSystem: operatingSystem(),
      keys: keyApi(),
      rules: {
        phoneNumber: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, max: 11, message: "请规范输入手机号", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        type: [
          {
            type: "array",
            required: true,
            message: "请阅读并同意用户服务协议",
            trigger: "change",
          },
        ],
      },
      form: {
        phoneNumber: "",
        password: "",
        type: [],
      },
      // rules2: {
      //   phoneNumber: [{ required: true, message: '请输入手机号', trigger: 'blur' },
      //     { min: 11, max: 11, message: '请规范输入手机号', trigger: 'blur' },],
      //   password:[{required: true, message: '请输入验证码', trigger: 'blur' }],
      //   type:[{type: 'array', required: true, message: '请阅读并同意服务协议和隐私政策', trigger: 'change',}]
      // },
      // form2: {
      //   phoneNumber:'',
      //   password: '',
      //   type:[],
      // },

      form2: this.$form.createForm(this),
      fvalidateStatus: "", //error
      helpMessage: "", //请填写手机号码
      fvalidatePass: "", //error
      helpPass: "", //请填写手机号码
      fvalidateType: "", //error
      helpType: "", //请填写手机号码

      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      phoneCodeLoginDisabled: false,
      phoneCodeLoginText: "获取验证码",
    };
  },

  methods: {
    async successNext(type, phone, password) {
      let data = {
        tel: phone, //手机号
        password: password, //验证码
        os: this.operatingSystem,
        version: "1.0.0",
        channel: "web",
      };
      let response;
      if (type === "MMDL") {
        //密码登录
        response = await login(data);
      } else if (type === "YZMDL") {
        //验证码登录
        response = await postLoginPhoneCodeSubmit(data);
      }
      if (response.code === 0) {
        //登陆后设置enableMessagePrompt为true,初次登陆需要弹消息或系统提醒弹窗
        await this.$store.dispatch("enableMessagePrompt", true)
        //登录成功获取到的数据,并转为json格式
        this.content = JSON.stringify(response.data);
        //向本地存储信息
        window.localStorage.setItem("yichi_userInfo", this.content);
        const id = response.data.id;
        const token = response.data.token;
        const type = response.data.type;
        //赋值x-token
        await setXToken(id, token, type);
        //赋值埋点id
        await setUserId(id);
        this.$message.success("登录成功");
        window.open(this.headerConfig.webLink, "_self");
      } else {
        this.$message.warning(response.message);
      }
    },
    openLink() {
      window.open("../userAg.html");
    },
    callback() {
      // this.$refs.ruleForm.resetFields();
      // this.$refs.ruleForm2.resetFields();
    },
    onSubmit(type) {
      if (type === "MMDL") {
        //密码登录
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            let form = this.form;
            await this.successNext(
              type,
              form.phoneNumber,
              form.password
            );
          } else {
            return false;
          }
        });
      }
      // else if(type === 'YZMDL'){ //验证码登录
      //   this.$refs.ruleForm2.validate(async valid => {
      //     if (valid) {
      //       let form2 = this.form2;
      //       await this.acquisitionLocation(type,form2.phoneNumber,form2.password);
      //     } else {
      //       return false;
      //     }
      //   });
      // }
    },
    handleSubmit(e) {
      //验证码登录
      e.preventDefault();
      this.form2.validateFields(async (err, values) => {
        if (!err) {
          await this.successNext(
            "YZMDL",
            values.phoneNumber,
            values.password
          );
        }
      });
    },
    validateMobile(rule, value, callback) {
      if (!value) {
        this.fvalidateStatus = "error";
        this.helpMessage = "请输入手机号";
      } else if (!/^1[0-9]{10}$/.test(value)) {
        this.fvalidateStatus = "error";
        this.helpMessage = "手机号码格式不正确";
      } else {
        callback();
        this.fvalidateStatus = "";
        this.helpMessage = "";
      }
    },
    validateType(rule, value, callback) {
      if (!value) {
        this.fvalidateType = "error";
        this.helpType = "请阅读并同意用户服务协议";
      } else {
        callback();
        this.fvalidateType = "";
        this.helpType = "";
      }
    },
    validatePass(rule, value, callback) {
      if (!value) {
        this.fvalidatePass = "error";
        this.helpPass = "请输入验证码";
      } else {
        callback();
        this.fvalidatePass = "";
        this.helpPass = "";
      }
    },
    async phoneCodeLogin() {
      const form = this.form2;
      let phoneNumber = form.getFieldValue("phoneNumber");
      let type = form.getFieldValue("type");
      //验证码登录-获取验证码
      if (phoneNumber) {
        if (type) {
          let data = {
            tel: phoneNumber,
          };
          const response = await postLoginPhoneCode(data);
          if (response.code === 0) {
            this.$message.success("发送成功");
            this.phoneCodeLogin60s(); // 调用验证码倒计时
          } else {
            this.$message.warning(response.message);
          }
        } else {
          // this.$message.info("请先勾选用户协议~");
          this.fvalidateType = "error";
          this.helpType = "请阅读并同意用户服务协议";
        }
      } else {
        // this.$message.warning("请填写手机号码");
        this.fvalidateStatus = "error";
        this.helpMessage = "请输入手机号";
      }
    },
    phoneCodeLogin60s() {
      // 验证码登录-获取验证码-60s倒计时
      this.phoneCodeLoginDisabled = true;
      let count = 60;
      let interval = setInterval(() => {
        count--;
        this.phoneCodeLoginText = count + "s";

        if (count === 0) {
          clearInterval(interval);
          this.phoneCodeLoginText = "重新获取";
          this.phoneCodeLoginDisabled = false;
        }
      }, 1000);
    },
    forgetPassword() {
      //忘记密码
      this.$store.dispatch("loginShow", 2);
    },
    registerBtn() {
      //注册
      this.$store.dispatch("loginShow", 4);
    },
  },
};
</script>

<style scoped lang="scss">
.ant-input {
  //border-radius: 0;
}
.AccountPassword {
  width: 100%;
  .input {
    width: 382px;
    //border-radius: unset;
  }
  .custom-input-password {
    width: 382px;
    //border-radius: unset;
  }
  .btnSty {
    background: linear-gradient(to right, #6fb1ff);
    border: none;
  }
  .btnFont {
    margin-top: -15px;
    width: 382px;
    display: flex;
    justify-content: space-between;
    .leftFont {
      color: #999999;
      font-size: 12px;
      cursor: pointer;
    }
    .leftFont:hover {
      color: #3989f5;
    }
  }
  .ant-input {
    .ant-input-affix-wrapper .ant-input:not(:first-child) {
      padding-left: 40px;
    }
  }
}
</style>
