//全局变量
/**
 * 提示：添加新变量后，请重新启动项目
 *  */
const variables = {
    // div内的内容98%
    space_div:'98%',
    // 查询条件左侧标题颜色透明度
    topTitleColor:'rgb(0,0,0, .85)',
    // 查询条件后条件名称
    topNameColor:'rgb(0,0,0, .65)',
    // 查询名称与组件间距离
    nameInputSpace:'5px',
    //灰色字体
    greyFont:'#a0a2a3',
    //模块标题行间距
    LineSpacing:'15px',
    //模块标题下划线
    titleLines:'1px solid #edeff2',
    //登录中间的矩形框宽高固定
    loginWidth:'1021px',
    loginHeight:'477px',
    //登录页login宽度
    loginLogo:'66px',
}
module.exports = variables
