//判断当前操作系统是PC、Android 还是 IOS
export function operatingSystem(){
    let p = navigator.platform;
    let u = navigator.userAgent;
    let is_android = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
    let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (p == "Win32" || p == "Win64" || p == "MacPPC" || p == "MacIntel" || p == "X11" || p == "Linux i686") {//PC调试环境
        return p
    }
    else {
        if (is_android) {//Android终端
            return 'Android'
        }
        else if (is_ios) {//IOS终端
            return 'IOS'
        }
    }
}
