<template>
  <div class="Register">
    <!-- 注册-->
    <h2
      style="
        font-weight: bold;
        color: #333333;
        letter-spacing: 3px;
        text-align: center;
        margin-top: 50px;
      "
    >
      注册成功，即可免费试用
    </h2>
    <div style="margin-top: 20px">
      <a-form :form="form" @submit="onSubmit">
        <a-form-item :validate-status="fvalidateName" :help="helpName">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [
                  { required: true, message: '请输入您的姓名' },
                  { validator: validateName },
                ],
              },
            ]"
            placeholder="请输入您的姓名"
          />
        </a-form-item>
        <a-form-item :validate-status="fvalidateStatus" :help="helpMessage">
          <a-input
            v-decorator="[
              'phoneNumber',
              {
                rules: [
                  { required: true, message: '请填写手机号码' },
                  { validator: validateMobile },
                ],
              },
            ]"
            type="tel"
            placeholder="请输入手机号"
          />
        </a-form-item>
        <a-form-item :validate-status="fvalidatePass" :help="helpPass">
          <a-input
            v-decorator="[
              'password',
              {
                rules: [
                  { required: true, message: '请填写验证码' },
                  { validator: validatePass },
                ],
              },
            ]"
            type="tel"
            placeholder="请输入验证码"
          >
            <span slot="suffix">
              <a @click="phoneCodeLogin" :disabled="phoneCodeLoginDisabled">{{
                phoneCodeLoginText
              }}</a>
            </span>
          </a-input>
        </a-form-item>
        <a-form-item :validate-status="fvalidateType" :help="helpType">
          <a-checkbox-group
            v-decorator="[
              'type',
              {
                rules: [
                  { required: true, message: '请阅读并同意用户服务协议' },
                  { validator: validateType },
                ],
              },
            ]"
            style="text-align: left; width: 382px"
          >
            <a-checkbox value="1" name="type">
              <span style="color: #999999; font-size: 12px"
                >登录前请仔细阅读</span
              >
            </a-checkbox>
            <a style="color: #3989f5" @click="openLink">《用户服务协议》</a>
          </a-checkbox-group>
        </a-form-item>

        <!-- 其他表单项 -->

        <a-form-item>
          <a-button
            type="primary"
            class="input btnSty"
            style="height: 40px"
            html-type="submit"
            >登录</a-button
          >
        </a-form-item>
        <div
          style="
            margin-top: -15px;
            width: 382px;
            display: flex;
            justify-content: center;
          "
        >
          <a style="font-size: 12px" @click="returnAccoutPassW"
            >已有账号，快速登录>></a
          >
        </div>
      </a-form>
    </div>
    <!--选择弹窗-->
    <a-modal v-model="visible" width="400px" @cancel="cancel">
      <p>恭喜您成功注册医驰后台管理系统！</p>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button @click="handleOk('cancel')"> 客服介绍 </a-button>
        <a-button
          style="margin-left: 190px"
          type="primary"
          @click="handleOk('ok')"
        >
          直接进入
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  adminLoginRegister,
  adminRegisterSendSMS,
  setUserId,
} from "@/service/common";
import { jsonp } from "@/utils/jsonp";
import { setXToken } from "@/service/base";
import { keyApi } from "@/utils/keyVal";
import { operatingSystem } from "@/utils/getOperatingSystem";
import headerConfig from "@/service/api_header_config";
export default {
  name: "Register",
  data() {
    return {
      headerConfig:headerConfig,
      keys: keyApi(),
      // rules: {
      //   phoneNumber: [{ required: true, message: '请输入手机号', trigger: 'blur' },
      //     { min: 11, max: 11, message: '请规范输入手机号', trigger: 'blur' },],
      //   password:[{required: true, message: '请输入验证码', trigger: 'blur' }],
      //   name:[{required: true, message: '请输入您的姓名', trigger: 'blur' }],
      //   // nameFirm:[{required: true, message: '请输入您的企业名称', trigger: 'blur' }],
      //   type:[{type: 'array', required: true, message: '请阅读并同意服务协议和隐私政策', trigger: 'change',}]
      // },
      // form: {
      //   name:'',
      //   // nameFirm:'',
      //   phoneNumber:'',
      //   password: '',
      //   type:[],
      // },

      form: this.$form.createForm(this),
      fvalidateStatus: "", //error
      helpMessage: "", //请填写手机号码
      fvalidatePass: "", //error
      helpPass: "", //请输入验证码
      fvalidateType: "", //error
      helpType: "", //请阅读并同意服务协议和隐私政策
      fvalidateName: "", //error
      helpName: "", //请输入您的姓名

      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      phoneCodeLoginDisabled: false,
      phoneCodeLoginText: "获取验证码",
      visible: false,
      operatingSystem: operatingSystem(),
    };
  },
  methods: {
    cancel() {
      this.visible = false;
    },
    returnAccoutPassW() {
      this.$store.dispatch("loginShow", 1);
    },
    async handleOk() {
      //向本地存储信息
      let row = this.content;
      window.localStorage.setItem("yichi_userInfo", row);
      const id = row.id;
      const token = row.token;
      const type = row.type;
      //赋值x-token
      await setXToken(id, token, type);
      //赋值埋点id
      await setUserId(id);
      this.$message.success("登录成功");
      window.open(this.headerConfig.webLink, "_self"); //商户身份
    },
    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let dataList = {
            name: values.name,
            tel: values.phoneNumber,
            password: values.password,
            os: this.operatingSystem,
            version: "1.0.0",
            channel: "web"
          }
          // // 表单校验通过，执行登录逻辑
          const response = await adminLoginRegister(dataList); //验证码登录
          if (response.code === 200) {
            // //登录成功获取到的数据,并转为json格式
            this.content = JSON.stringify(response.data);
            await this.handleOk(); //进入后台
          } else {
            this.$message.warning(response.message);
          }
        }
      });
    },
    openLink() {
      window.open("../userAg.html");
    },
    async phoneCodeLogin() {
      //验证码登录-获取验证码
      const form = this.form;
      let phoneNumber = form.getFieldValue("phoneNumber");
      let type = form.getFieldValue("type");
      if (phoneNumber) {
        if (type) {
          let data = {
            tel: phoneNumber,
          };
          const response = await adminRegisterSendSMS(data);
          if (response.code === 0) {
            this.$message.success("发送成功");
            this.phoneCodeLogin60s(); // 调用验证码倒计时
          } else {
            this.$message.warning(response.message);
          }
        } else {
          this.fvalidateType = "error";
          this.helpType = "请阅读并同意用户服务协议";
          // this.$message.info("请先勾选用户协议~");
        }
      } else {
        this.fvalidateStatus = "error";
        this.helpMessage = "请填写手机号码";
        // this.$message.warning("请填写手机号码");
      }
    },
    phoneCodeLogin60s() {
      // 验证码登录-获取验证码-60s倒计时
      this.phoneCodeLoginDisabled = true;
      let count = 60;
      let interval = setInterval(() => {
        count--;
        this.phoneCodeLoginText = count + "s";

        if (count === 0) {
          clearInterval(interval);
          this.phoneCodeLoginText = "重新获取";
          this.phoneCodeLoginDisabled = false;
        }
      }, 1000);
    },
    validateName(rule, value, callback) {
      if (!value) {
        this.fvalidateName = "error";
        this.helpName = "请输入您的姓名";
      } else {
        callback();
        this.fvalidateName = "";
        this.helpName = "";
      }
    },
    validateMobile(rule, value, callback) {
      if (!value) {
        this.fvalidateStatus = "error";
        this.helpMessage = "请输入手机号";
      } else if (!/^1[0-9]{10}$/.test(value)) {
        this.fvalidateStatus = "error";
        this.helpMessage = "手机号码格式不正确";
      } else {
        callback();
        this.fvalidateStatus = "";
        this.helpMessage = "";
      }
    },
    validatePass(rule, value, callback) {
      if (!value) {
        this.fvalidatePass = "error";
        this.helpPass = "请填写验证码";
      } else {
        callback();
        this.fvalidatePass = "";
        this.helpPass = "";
      }
    },
    validateType(rule, value, callback) {
      if (!value) {
        this.fvalidateType = "error";
        this.helpType = "请阅读并同意用户服务协议";
      } else {
        callback();
        this.fvalidateType = "";
        this.helpType = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.Register {
  width: 100%;
  .input {
    width: 382px;
  }
  .btnSty {
    background: linear-gradient(to right, #6fb1ff, #095fff);
    border: none;
  }
}
</style>
