<template>
  <div class="login">
    <div class="loginTop">
      <div>
        <img src="../assets/yich_logo.png" alt="" />
      </div>
      <div style="margin-top: 20px">
        <a @click="openWeb">首页</a>
      </div>
    </div>
    <div class="loginCont">
      <div class="cont">
        <div class="leftImg">
          <img src="../assets/login/loginLeft.png" alt="" />
        </div>
        <div class="rightCont">
          <div class="commentVue">
            <!--账号密码/验证码登录-->
            <AccountPassword v-if="loginShow === 1" />
            <!--找回密码-验证手机号-->
            <RetrievePassword v-if="loginShow === 2" />
            <!--找回密码-设置密码-->
            <SetPassword v-if="loginShow === 3" />
            <!--注册-->
            <Register v-if="loginShow === 4" />
          </div>
        </div>
      </div>
    </div>
    <div class="rightXF">
      <div v-show="iconShow">
        <a-popover placement="leftTop">
          <template slot="content">
<!--            <p style="color: #b3b3b3">客服电话：4008-554-120</p>-->
            <div>
              <img style="width: 200px" src="../assets/login/consultant.png" alt=""/>
<!--              <img style="height: 200px" :src="KFImage" alt=""/>-->
            </div>
          </template>
          <div class="consult">
            <div style="font-size: 22px; border: 1px dashed #f5f5f5">
              <admin_con type="icon-zixunjilu" />
            </div>
            <div style="margin-top: -3px">咨询</div>
          </div>
        </a-popover>
      </div>
      <div v-show="iconShow">
        <a-popover placement="left">
          <template slot="content">
            <div>感谢您的关注，我们期待您的建议</div>
            <div style="margin-top: 10px">投诉建议邮箱：wangxy@yichimeeting.com</div>
          </template>
          <div class="feedback">
            <div style="font-size: 19px"><admin_con type="icon-drxx64" /></div>
            <div style="margin-top: 1px">反馈</div>
          </div>
        </a-popover>
      </div>
      <div v-show="iconShow">
        <a-popover placement="leftBottom">
          <template slot="content">
            <img style="width: 200px" src="../assets/login/code.png" alt="" />
            <div style="width: 100%; text-align: center">
              <div>微信扫一扫</div>
              <div>关注医驰会务官方公众号</div>
            </div>
          </template>
          <div class="QRCode">
            <div style="font-size: 28px">
              <admin_con type="icon-erweima1" />
            </div>
          </div>
        </a-popover>
      </div>
      <div @click="icon">
        <div class="jiantuo">
          <div style="font-size: 27px; margin-left: 3px">
            <admin_con type="icon-xiangyoushuangjiantou" v-if="iconShow" />
            <admin_con type="icon-xiangzuoshuangjiantou" v-else />
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      广州市优屏科技有限公司版权所有
      <span class="link" @click="openLink">粤ICP备2022095871号</span>
    </div>
  </div>
</template>
<script>
import variables from "@/assets/base.scss";
import { admin_icon } from "@/utils/myIcon";
import AccountPassword from "@/components/login/AccountPassword";
import Register from "@/components/login/Register";
import RetrievePassword from "@/components/login/RetrievePassword";
import SetPassword from "@/components/login/SetPassword";
// import {exclusiveKFImage} from "@/service/authentication";
export default {
  components: {
    SetPassword,
    RetrievePassword,
    Register,
    AccountPassword,
    admin_con: admin_icon,
  },
  data() {
    return {
      variables,
      iconShow: true,
      /**
       * 默认显示-账号密码/验证码登录 - 1
       * 找回密码 - 2
       * 设置密码 - 3
       * 注册 - 4
       */
      // isShow:1,
      // KFImage:'',
    };
  },
  computed: {
    /**
     * 默认显示-账号密码/验证码登录 - 1
     * 找回密码 - 2
     * 设置密码 - 3
     * 注册 - 4
     */
    loginShow() {
      return this.$store.state.loginShow;
    },
  },
  created() {
    // this.getKFImage();
  },
  methods: {
    //获取客服二维码
    // async getKFImage() {
    //   const response = await exclusiveKFImage();
    //   if (response.code === 200) {
    //     this.KFImage = response.data;
    //   } else {
    //     this.$message.warning(response.message);
    //   }
    // },
    openLink() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
    openWeb() {
      window.open("https://www.yichimeeting.com");
    },
    icon() {
      this.iconShow = !this.iconShow;
    },
  },
};
</script>
<style lang="scss" scoped>
.ant-input {
  border-radius: 0;
}
.login {
  height: 100%;
  width: 100%;
  background-image: url("../assets/login/bgYiCi.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .loginTop {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: auto;
    img {
      width: 66px;
    }
  }
  .loginCont {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    .cont {
      border-radius: 10px;
      overflow: hidden;
      width: $loginWidth;
      height: $loginHeight;
      background-color: #fff;
      box-shadow: 1px 1px 15px #d7d7d7;
      display: flex;
      .leftImg {
        width: 50%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .rightCont {
        width: 50%;
        .commentVue {
          width: 75%;
          margin: auto;
          //margin-top: 50px;
          //text-align: center;
        }
      }
    }
  }
  .rightXF {
    position: fixed;
    right: 20px;
    bottom: 45%;

    .consult {
      width: 36px;
      text-align: center;
      height: 55px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 1px 1px 15px #d7d7d7;
      font-size: 12px;
      color: rgb(0, 0, 0, 0.85);
      margin-bottom: 10px;
      .img {
        width: 60px;
      }
    }
    .feedback {
      width: 36px;
      text-align: center;
      height: 55px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 1px 1px 15px #d7d7d7;
      font-size: 12px;
      color: rgb(0, 0, 0, 0.85);
      margin-bottom: 10px;
    }
    .QRCode {
      width: 36px;
      text-align: center;
      height: 45px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 1px 1px 15px #d7d7d7;
      font-size: 12px;
      color: rgb(0, 0, 0, 0.85);
      margin-bottom: 10px;
    }
    .jiantuo {
      width: 36px;
      text-align: center;
      height: 42px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 1px 1px 15px #d7d7d7;
      font-size: 12px;
      color: rgb(0, 0, 0, 0.85);
    }
  }
  .bottom {
    color: #999999;
    font-size: 12px;
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 24px;
  }
  .link {
    cursor: pointer;
  }
  .link:hover {
    color: #3989f5;
  }
}
</style>
