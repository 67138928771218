<template>
  <div class="RetrievePassword">
    <!--  设置密码  -->
    <a @click="returnAccoutPassW"><a-icon type="left" /> 返回登录</a>
    <h2 style="font-weight: bold;color: #333333;letter-spacing:3px;text-align: center;margin-top: 30px">找回密码-设置密码</h2>
    <div style="margin-top: 40px">
      <a-form-model
          ref="ruleForm2"
          :model="form"
          :rules="rules2"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item prop="password">

          <a-input size="large" class="input" v-model="form.password" placeholder="请输入(8~16位的字母、数字组合) "/>

        </a-form-model-item>
        <a-form-model-item prop="passwords">
          <!--            <a-input class="input" v-model="form.password" placeholder="请输入验证码"/>-->
          <a-input
              class="input"
              size="large"
              v-model="form.passwords"
              placeholder="请输入确认密码"
              @keydown.enter="onSubmit"/>
        </a-form-model-item>
        <a-form-model-item>
          <a-button size="large" type="primary" @click="onSubmit" class="input btnSty" style="margin-top: 30px;height: 40px">
            立即提交
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import {updLoginPassWord} from "@/service/common";

export default {
  name: "RetrievePassword",
  data(){
    return{
      rules2: {
        password:[{required: true, message: '请输入登录密码', trigger: 'blur'},
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,
            message: "请输入8~16位字母、数字组合",
            trigger: "blur",
          },
        ],
        passwords:[{required: true, message: '请输入确认密码', trigger: 'blur' }],
      },
      form: {
        passwords:'',
        password: '',
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
    }
  },

  methods:{
    returnAccoutPassW(){
      this.$store.dispatch("loginShow", 1);
    },
    callback(){

    },
    onSubmit(){
      this.$refs.ruleForm2.validate(async valid => {
        if (valid) {
          let form = this.form;
          if(form.password === form.passwords){
            let data = {
              "password": form.passwords//验证码
            }
            const response = await updLoginPassWord(data)
            if(response.code === 200){
              this.$message.success("密码修改成功，请重新登录！")
              this.$store.dispatch("loginShow", 1);
            }else {
              this.$message.warning("验证码不正确！")
            }
          }else {
            this.$message.warning("设置密码不一致！")
          }
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">

.RetrievePassword{
  width: 100%;
  margin-top: 20px;
  .input{
    width: 382px;
  }
  .btnSty{
    background: linear-gradient(to right, #6FB1FF, #095FFF);
    border: none;
  }
  .ant-input {
    .ant-input-affix-wrapper .ant-input:not(:first-child) {
      padding-left: 40px;
    }
  }

}
</style>
